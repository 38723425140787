import React, { useContext, useEffect, useState, useMemo } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import {
  createBrowserRouter,
  RouterProvider,
  Link,
  Outlet,
  useParams,
  useNavigate,
} from "react-router-dom";

import "./index.css";

import videojs from "video.js";
import "video.js/dist/video-js.css";

import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";

const ExtLink = ({ children, to }) => {
  return (
    <a href={to} target="_blank" rel="noopener">
      {children}
    </a>
  );
};

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log("player is ready");
        onReady && onReady(player);
      }));

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

const CENTER_PANEL = { maxWidth: 800, margin: "auto" };
const ACTION_LINK = {
  fontWeight: "bold",
  textDecoration: "underline",
  color: "black",
};
const NICER_INPUT = { marginRight: "1em" };
const INSET_DIV = { padding: "1em 2em" };
const BIG_DIV = { width: "100%", overflowX: "auto", margin: "auto" };

function prettyPrintFileSize(bytes) {
  const units = ["B", "KB", "MB", "GB", "TB"];
  let size = bytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return size.toFixed(2) + " " + units[unitIndex];
}

const OAUTH_CLIENT_ID =
  "1050812905648-qk0uadr5ngv8m64kpk9oha6t1a6j3p3h.apps.googleusercontent.com";

const ShareLinks = ({ embed }) => {
  const [loading, setLoading] = useState();
  const [out, setOut] = useState();

  if (!out) {
    return (
      <div style={{ ...INSET_DIV, ...CENTER_PANEL }}>
        <button
          onClick={() => {
            const fn = async () => {
              setLoading(true);

              const res = await (
                await fetch("/api/list-bucket?share=true")
              ).json();
              setOut(res);

              setLoading(false);
            };
            fn();
          }}
          disabled={loading}
        >
          {loading
            ? "Loading..."
            : embed
              ? "View embed codes"
              : "Manage share links"}
        </button>
      </div>
    );
  }

  return (
    <div style={BIG_DIV}>
      <table border={1}>
        <tr>
          <th>id</th>
          <th>name</th>
          <th>dimensions</th>
          <th>links</th>
          {embed ? <th>embed</th> : ""}
        </tr>
        {Object.entries(out).map(([uid, { title, name, links, videosize }]) => (
          <tr>
            <td>{uid}</td>
            <td>{title || name}</td>
            <td>
              {!videosize ? "" : `${videosize.width}x${videosize.height}`}
            </td>
            <td>
              {(links || []).map((share_token) => (
                <span>
                  <a href={`/watch/${share_token}`} target="_blank">
                    {share_token.slice(0, 8)}
                  </a>{" "}
                </span>
              ))}
              <button
                disabled={loading || (links || []).length}
                onClick={() => {
                  const fn = async () => {
                    setLoading(true);
                    const res = await (
                      await fetch("/api/create-play-link", {
                        method: "POST",
                        body: JSON.stringify({ id: uid }),
                      })
                    ).json();

                    // mutate data model in-place
                    const newOut = { ...out };

                    newOut[uid].links = [
                      ...(newOut[uid].links || []),
                      res.share_token,
                    ];
                    setOut(newOut);
                    setLoading(false);
                  };
                  fn();
                }}
              >
                New link
              </button>{" "}
              <button
                disabled={loading || !(links || []).length}
                style={{ color: "red" }}
                onClick={() => {
                  const fn = async () => {
                    setLoading(true);
                    for (const share_token of links) {
                      const res = await (
                        await fetch("/api/remove-play-link", {
                          method: "POST",
                          body: JSON.stringify({ id: share_token }),
                        })
                      ).json();
                    }

                    const newOut = { ...out };

                    newOut[uid].links = [];
                    setOut(newOut);
                    setLoading(false);
                  };

                  if (
                    prompt(
                      "Are you sure? All embeds for this video will break! Type 'yes' to continue.",
                    ) === "yes"
                  ) {
                    fn();
                  }
                }}
              >
                Clear links
              </button>
            </td>
            {!embed ? (
              ""
            ) : (
              <td>
                {!links ? (
                  "Create a share link to get the embed code"
                ) : (
                  <textarea readonly onClick={(e) => e.target.select()}>
                    {`<div style="position: relative; width: 100%; height: 0; padding-bottom: ${100 * (videosize.height / videosize.width)}%;"><iframe allowfullscreen="true" frameborder="0" src="https://cdn.palestinefilminstitute.org/watch/${links[0]}" style="position:absolute;left: 0;top: 0;height: 100%; width: 100%;"></iframe></div>`}
                  </textarea>
                )}
              </td>
            )}
          </tr>
        ))}
      </table>
    </div>
  );
};

const ListBucket = () => {
  const [loading, setLoading] = useState();
  const [out, setOut] = useState();

  if (!out) {
    return (
      <div style={{ ...INSET_DIV, ...CENTER_PANEL }}>
        <button
          onClick={() => {
            const fn = async () => {
              setLoading(true);

              const res = await (
                await fetch("/api/list-bucket?size=true")
              ).json();
              setOut(res);

              setLoading(false);
            };
            fn();
          }}
          disabled={loading}
        >
          {loading ? "Loading..." : "List storage bucket"}
        </button>
      </div>
    );
  }

  return (
    <div style={BIG_DIV}>
      <table border={1}>
        <tr>
          <th>id</th>
          <th>name</th>
          <th>size</th>
        </tr>
        {Object.entries(out).map(([uid, { title, filesize, name }]) => (
          <tr>
            <td>{uid}</td>
            <td>{title || name}</td>
            <td>{prettyPrintFileSize(filesize || 0)}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

const VimeoImport = () => {
  const [vimeoUrl, setVimeoUrl] = useState("");
  const [vimeoPass, setVimeoPass] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [output, setOutput] = useState();

  return (
    <div style={{ ...INSET_DIV, ...CENTER_PANEL }}>
      <input
        style={NICER_INPUT}
        placeholder="vimeo url"
        value={vimeoUrl}
        onChange={(e) => setVimeoUrl(e.target.value)}
      />
      <input
        style={NICER_INPUT}
        placeholder="vimeo password"
        value={vimeoPass}
        onChange={(e) => setVimeoPass(e.target.value)}
      />
      <button
        disabled={loading}
        onClick={() => {
          const fn = async () => {
            setOutput();
            setError();
            setLoading(true);

            try {
              const out = await (
                await fetch("/api/vimeo-dl", {
                  method: "POST",
                  body: JSON.stringify({ url: vimeoUrl, pass: vimeoPass }),
                })
              ).json();

              setOutput(out);
            } catch (e) {
              console.warn(e);
              setError(true);
            }

            setLoading(false);
          };

          fn();
        }}
      >
        {loading ? "downloading..." : "submit"}
      </button>
      {!error ? (
        ""
      ) : (
        <div style={{ marginTop: 10 }}>
          <b>Error. Check URL/password, or try again in a few minutes...</b>
        </div>
      )}
      {!output ? (
        ""
      ) : (
        <div style={{ marginTop: 10 }}>
          <i>
            Success! <b>{output.title}</b> is downloading from on the server.
            I&rsquo;ll send you an email when the download and transcoding is
            finished.
          </i>
        </div>
      )}
    </div>
  );
};

const GoogleDriveImport = ({ disabled }) => {
  const [loading, setLoading] = useState(false);
  const [films, setFilms] = useState();

  const login = useGoogleLogin({
    flow: "initCodeClient",
    onSuccess: (tokenResponse) => {
      const signIn = async () => {
        setLoading(true);

        const res = await fetch("/sign-up-with-gmail", {
          method: "post",
          body: JSON.stringify(tokenResponse),
        });
        const info = await res.json();

        setLoading(false);

        console.log(info);

        window.location.reload();
      };

      signIn();
    },
    scope: "profile email https://www.googleapis.com/auth/drive.readonly",
  });

  return (
    <div style={{ ...INSET_DIV, ...CENTER_PANEL }}>
      <button disabled={disabled || loading} onClick={() => login()}>
        {loading ? "loading..." : "sign in with google"}
      </button>

      <button
        disabled={disabled || loading}
        onClick={() => {
          const fn = async () => {
            setLoading(true);

            const res = await (await fetch("/api/list-drive-videos")).json();
            setFilms(res);

            setLoading(false);
          };

          fn();
        }}
      >
        {loading ? "loading..." : "list google drive videos"}
      </button>

      {!films ? (
        ""
      ) : (
        <ul>
          {films.map(({ id, name }) => (
            <li key={id}>
              {name}{" "}
              <button
                onClick={() => {
                  const fn = async () => {
                    const res = await (
                      await fetch("/api/download-drive-video", {
                        method: "POST",
                        body: JSON.stringify({ id }),
                      })
                    ).json();
                  };

                  fn();
                }}
              >
                import
              </button>{" "}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const SubtitlesAndPosterFrames = () => {
  const [loading, setLoading] = useState();
  const [out, setOut] = useState();

  const [langs, setLangs] = useState([]);

  useEffect(() => {
    if (!out) return;

    const langs = ["en", "ar"];
    for (const { subs } of Object.values(out)) {
      if (!subs) continue;
      for (const lang of Object.keys(subs)) {
        if (langs.indexOf(lang) >= 0) continue;

        langs.push(lang);
      }
    }

    setLangs(langs);
  }, [out]);

  if (!out) {
    return (
      <div style={{ ...INSET_DIV, ...CENTER_PANEL }}>
        <button
          onClick={() => {
            const fn = async () => {
              setLoading(true);

              const res = await (await fetch("/api/list-bucket")).json();
              setOut(res);

              setLoading(false);
            };
            fn();
          }}
          disabled={loading}
        >
          {loading ? "Loading..." : "Subtitles and poster frames"}
        </button>
      </div>
    );
  }

  return (
    <div style={BIG_DIV}>
      <table border={1}>
        <tr>
          <th>id</th>
          <th>name</th>
          <th>poster</th>
          {langs.map((lang) => (
            <th key={lang}>{lang}.srt</th>
          ))}
          <th>
            <button
              onClick={() => {
                const langCode = prompt("Enter language code:");
                if (langCode) {
                  setLangs([...langs, langCode]);
                }
              }}
            >
              new lang
            </button>
          </th>
        </tr>

        {Object.entries(out).map(([uid, { title, name, poster, subs }]) => (
          <tr key={uid}>
            <td>{uid}</td>
            <td>{title || name}</td>
            <td>
              {poster || ""}{" "}
              <input
                onChange={(e) => {
                  if (!e.target.files) return;

                  const fn = async () => {
                    const nameParts = e.target.files[0].name.split(".");
                    const ext = nameParts[nameParts.length - 1];

                    await fetch(`/api/poster-frame?video=${uid}&ext=${ext}`, {
                      method: "POST",
                      body: e.target.files[0],
                    });

                    const newOut = { ...out };
                    newOut[uid].poster = `poster.${ext}`;
                    setOut(newOut);
                  };

                  fn();
                }}
                type="file"
              />
            </td>
            {langs.map((lang) => (
              <td key={lang}>
                {subs && subs[lang] ? subs[lang] : ""}
                <input
                  onChange={(e) => {
                    if (!e.target.files) return;

                    const fn = async () => {
                      await fetch(`/api/add-subs?video=${uid}&lang=${lang}`, {
                        method: "POST",
                        body: e.target.files[0],
                      });

                      const newOut = { ...out };
                      newOut[uid].subs = newOut[uid].subs || {};
                      newOut[uid].subs[lang] = `${lang}.srt`;

                      setOut(newOut);
                    };
                    fn();
                  }}
                  type="file"
                />
              </td>
            ))}
            <td />
          </tr>
        ))}
      </table>
    </div>
  );
};

const TX_LANGS = {
  ar: "Arabic",
  bn: "Bengali",
  de: "German",
  en: "English",
  es: "Spanish",
  fa: "Persian",
  fr: "French",
  he: "Hebrew",
  hi: "Hindi",
  ja: "Japanese",
  nl: "Dutch",
  pt: "Portuguese",
  sv: "Swedish",
  tr: "Turkish",
};

const SubtitleTranslation = () => {
  const [film, setFilm] = useState("");
  const [destLang, setDestLang] = useState("");
  const [srcLang, setSrcLang] = useState("");

  const [out, setOut] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSrcLang("");
  }, [film]);

  if (!out) {
    return (
      <div style={{ ...INSET_DIV, ...CENTER_PANEL }}>
        <button
          onClick={() => {
            const fn = async () => {
              setLoading(true);

              const res = await (await fetch("/api/list-bucket")).json();
              setOut(res);

              setLoading(false);
            };
            fn();
          }}
          disabled={loading}
        >
          {loading ? "Loading..." : "Generate translations"}
        </button>
      </div>
    );
  }

  return (
    <div>
      <label>
        Film:
        <select value={film} onChange={(e) => setFilm(e.target.value)}>
          <option key="null" value="" />
          {Object.entries(out)
            .filter(([uid, { subs }]) => subs)
            .map(([uid, { title, name }]) => (
              <option value={uid} key={uid}>
                {title || name}
              </option>
            ))}
        </select>
      </label>{" "}
      <br />
      <label>
        To:
        <select
          disabled={!film}
          value={destLang}
          onChange={(e) => setDestLang(e.target.value)}
        >
          <option key="null" value="" />
          {Object.entries(TX_LANGS).map(([code, name]) => (
            <option value={code} key={code}>
              {name}
            </option>
          ))}
        </select>
      </label>{" "}
      <br />
      <label>
        From:
        <select
          disabled={!film}
          value={srcLang}
          onChange={(e) => setSrcLang(e.target.value)}
        >
          <option key="null" value="" />
          {!out[film]
            ? ""
            : Object.keys(out[film].subs).map((lang) => (
                <option value={lang} key={lang}>
                  {TX_LANGS[lang]}
                </option>
              ))}
        </select>
      </label>{" "}
      <br />
      <button
        onClick={() => {
          const fn = async () => {
            setLoading(true);

            const res = await fetch(
              `/api/translate?video=${film}&src=${srcLang}&dest=${destLang}`,
              { method: "post" },
            );

            const header = res.headers.get("Content-Disposition");
            const parts = header.split(";");
            const filename = parts[1].split("=")[1].replaceAll('"', "");

            const blob = await res.blob();

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();

            setLoading(false);
          };

          fn();
        }}
        disabled={
          loading || !film || !destLang || !srcLang || destLang === srcLang
        }
      >
        {loading ? "Loading..." : "Translate"}
      </button>
    </div>
  );
};

const FILMS = {
  "6c1ac06e0ae84c8f81f5d1aa6b81f850": "Children Without Childhood",

  cd6199277ff848da8401aef3992b902b: "Revolution Until Victory",
  adc3bf8fd7a24422a8f82c68a78cb770: "Kufr Shuba",

  "7b00cc9e6e8344aea5f1e633db889894": "Why?",

  "6dfb2e5f88d2431caaaeac8d87290ed9": "One Hundred Faces for a Single Day",
  f02a80ce82624ca9a49b6b71590abb1d: "South Lebanon",

  cb1d7d7f166a4d53b8ecca86335e3683: "A Zionist Aggression",
  db61673a803b48ce9963b0a0c00fbcf4: "Maloul Celebrates its Destruction",
  a9636751398946778954cb04a78dca68: "Blown by The Wind",
  "8a53509aefaf4b34a0f82b15463390cd": "Aftermath",
};

const SimpleShareLinks = () => {
  return (
    <ul>
      {Object.entries(FILMS).map(([token, name]) => (
        <li>
          <ExtLink to={`/watch/${token}`}>{name}</ExtLink>
        </li>
      ))}
    </ul>
  );
};

const App = () => {
  const [loading, setLoading] = useState(false);
  const [films, setFilms] = useState(false);
  const [encodedVideos, setEncodedVideos] = useState();
  const [shareLinks, setShareLinks] = useState();
  const [player, setPlayer] = useState();

  const { whoami, db } = useContext(DB);

  return (
    <div>
      <div style={CENTER_PANEL}>
        <p style={{ textAlign: "right" }}>2024-10-25</p>
        <p style={{ textIndent: 0 }}>Dear all,</p>
        <p>Here are share links for the films:</p>
        <p>
          <SimpleShareLinks />
        </p>
        <p style={{ textIndent: 0 }}>-rm</p>
        <hr />

        <p style={{ textAlign: "right" }}>2024-10-11</p>
        <p style={{ textIndent: 0 }}>Dear Mohanad and Elhum,</p>
        <p>
          It&rsquo;s fitting enough to bring you back into the CDN letters on
          the question of translation. Isn&rsquo;t the whole project about
          accessibility, on different levels: from the grand narrative of
          Palestine all the way to the dissemination of digital files? And yes,
          of course, language.
        </p>

        <p>
          Here's a widget to generate subtitle translations, using Google's
          Cloud Translation API:
        </p>

        <SubtitleTranslation />

        <p>
          If you want to put them right into the platform, you can find the
          subtitle table in Casey&rsquo;s letter, below. But maybe these will
          circulate through the Google Drive, to poets and translators around
          the world, and finally back again?
        </p>

        <p>
          Much more to say. I&rsquo;m happy to be embarking on this crazy
          infrastructure adventure with all of you!
        </p>

        <p>Your correspondent,</p>
        <p>
          <i>R.M.O.</i>
        </p>
      </div>

      <hr />

      <div style={CENTER_PANEL}>
        <p style={{ textAlign: "right" }}>2024-10-04</p>
        <p style={{ textIndent: 0 }}>Dear Casey,</p>
        <p>So fun hanging out with you here in Rochester!</p>
        <p>
          Anyway, back to business, we're back to the Google Drive importer...
          this UI is a bit messy, but it worked for me to get the films in
          easily enough. Will leave it grayed out for now due to my
          embarrassment, but next time we need to load in new films from the
          drive I'll fix this up a bit..
        </p>
      </div>
      <GoogleDriveImport disabled={true} />

      <div style={CENTER_PANEL}>
        <p>
          Wow, so Vimeo gave PFI a <i>$50,000</i> quote as a baseline for the
          year, with bandwidth projections making it seem like you might need to
          go even higher?! That's insane. The peak bandwidth was a 20TB month,
          and a 1.2TB day (November 28, when all of Italy watched{" "}
          <i>
            <ExtLink to="https://www.imdb.com/title/tt21144238/">
              Erasmus in Gaza
            </ExtLink>
          </i>
          ). As we discussed, we can implement a web-based peer-to-peer
          distribution system (over the WebRTC data channel) to keep our costs
          low, but for now the Google Cloud prices should be fine. If we have a
          1TB day, it'll cost $80. Fine. And then we'll have a good excuse to
          implement the p2p system...
        </p>
        <p>
          OK, so the PFI CDN is now running in a dedicated Google Cloud project
          which you have access to, and we've set up the CNAME so that{" "}
          <code>cdn.palestinefilminstitute.org</code> serves up the files. So
          professional! I've configured the new Google Cloud project to keep all
          of the servers and files in Europe, because ... why not. So the
          computers are in Belgium and the storage bucket is
          &ldquo;multi-regional&rdquo; within Europe.
        </p>
        <p>
          To embed the video in the PFI website, we'll use an{" "}
          <code>iframe</code>
          -based method that uses{" "}
          <ExtLink to="https://stackoverflow.com/questions/25302836/responsive-video-iframes-keeping-aspect-ratio-with-only-css">
            this
          </ExtLink>{" "}
          weird CSS trick to encode the aspect ratio into the containing{" "}
          <code>div</code>'s padding. For simplicity at the moment, I'm going to
          limit to a single share link per video, but ... this is arbitrary. You
          can click on the embed area to select it, then copy and paste into
          Squarespace.
        </p>
      </div>
      <ShareLinks embed />
      <div style={CENTER_PANEL}>
        <p>
          Finally... subtitles and poster frames! I'm still working through the
          video player customization, but you'll be able to manage subtitles and
          poster frames (make sure to use the same aspect ratio as the video!)
          by using this table:
        </p>
      </div>
      <SubtitlesAndPosterFrames />
      <div style={CENTER_PANEL}>
        <p>
          This should get us moving for now... I'm going to do some prep work to
          optimize so everything is fast for Monday. More on this soon.
        </p>

        <p>Your correspondent,</p>
        <p>
          <i>R.M.O.</i>
        </p>

        <hr />
        <p style={{ textAlign: "right" }}>2024-09-06</p>
        <p style={{ textIndent: 0 }}>
          Dear Reem, Mohanad, Elhum, Omar, and Femke,
        </p>

        <p>
          I&rsquo;m on the Eurostar out of Brussels thinking of you all. As I
          didn&rsquo;t show this to you yet, I am left to convey it at distance,
          further and further by the moment.
        </p>

        <p>
          {" "}
          Firstly: the question of &ldquo;you.&rdquo; This raises the question
          of <i>authentication</i>, which my pirated OED tells me has an
          obsolete antecedent; the word &ldquo;authentic&rdquo; once meant
          &ldquo;entitled to obedience or respect.&rdquo; This is fitting enough
          to our discussions, I think, at least the respect part. Too many
          &ldquo;user&rdquo; systems take the drug addict analogy too far... I
          anticipate many conversations about how to model authentic people in
          our systems. At any rate, you seem to be <code>{whoami}</code>. If
          you&rsquo;re not happy with that, you can{" "}
          <a
            style={ACTION_LINK}
            href="#"
            onClick={() => {
              const fn = async () => {
                await fetch("/api/sign-out");
                window.location.reload();
              };
              fn();
            }}
          >
            sign out
          </a>
          .
        </p>
        <p>
          Wow, Paris already? That surprised me, the quickness of this journey.
          So let&rsquo;s get to it. You can add videos from Vimeo to the{" "}
          <i>storage bucket</i> of the PFI CDN, and then we&rsquo;ll transcode,
          serve, and embed them from here to bypass Vimeo's extortionate
          bandwidth pricing. Paste the Vimeo URL and password (if relevant)
          below to start:
        </p>
      </div>
      <VimeoImport />
      <div style={CENTER_PANEL}>
        <p>
          I started by writing a Google Drive importer. That seems less relevant
          for now, but we can keep it in mind if we want to host anything from
          the PFI Google Drive. The Vimeo importer uses{" "}
          <ExtLink to="https://github.com/yt-dlp/yt-dlp">yt-dlp</ExtLink>, which
          feels increasingly fragile: there are so many well-funded AI companies{" "}
          <i>scraping</i> the Internet these days that content repositories are
          tightening their controls. So, we may need to find other ways to
          import the source videos if this doesn&rsquo;t work reliably.
        </p>

        <p>
          Everything we import from Vimeo is then stored privately using Google
          Cloud Storage.{" "}
          <ExtLink to="https://cloud.google.com/storage/docs">They say</ExtLink>
          :{" "}
          <i>
            &ldquo;Cloud Storage allows world-wide storage and retrieval of any
            amount of data at any time.&rdquo;
          </i>{" "}
          Bold claims. Currently I&rsquo;m using multi-regional storage in the
          US, but we move the archive to the EU, to Asia,{" "}
          <ExtLink to="https://cloud.google.com/storage/pricing#regions">
            etc
          </ExtLink>
          ... It{" "}
          <ExtLink to="https://cloud.google.com/storage/pricing#multi-regions">
            costs
          </ExtLink>{" "}
          $0.026 per GB per month. So 100GB would cost $2.60/mo to store.
          Incidentally, the IDF uses Amazon's equivalent cloud storage
          extensively;{" "}
          <ExtLink to="https://www.972mag.com/cloud-israeli-army-gaza-amazon-google-microsoft/">
            this 972mag reporting
          </ExtLink>{" "}
          indicates that &ldquo;billions of audio files&rdquo; (mass-surveilled
          phone calls?) are stored in Amazon S3 buckets. What a creepy archive.
        </p>
      </div>

      <ListBucket />

      <div style={CENTER_PANEL}>
        <p>
          Now that we have private storage and a <i>data egress</i> strategy
          from Vimeo: distribution! I&rsquo;m currently encoding the video to an
          h264{" "}
          <ExtLink to="https://en.wikipedia.org/wiki/HTTP_Live_Streaming">
            HLS sequence
          </ExtLink>{" "}
          at 360p, 720p, and 1080p. This is a format that Apple made for the
          iPhone, and through their monopoly it seems by now to be the most
          universal way to disseminate video on the Internet. I can also add 2K
          and 4K encoding (probably using the av1 codec), but, for later.
          I&rsquo;m doing the transcoding with Google's <i>serverless</i>{" "}
          product, which bills{" "}
          <ExtLink to="https://cloud.google.com/run/pricing#tables">
            per-second
          </ExtLink>
          ; this way, it costs approximately $0.50 to encode an hour of video (a
          one-time cost per-video).
        </p>
        <p>
          Since the storage bucket is private, the next step is to{" "}
          <i>authenticate</i> access to the videos. My approach here is to
          generate <i>share tokens</i> that each grant access to one video.
          These tokens can be managed independently of the storage, so you could
          send someone a public link, and then decide a week later to remove
          their link without touching the underlying video in its storage
          bucket. We could also collect statistics on each share link&mdash;or
          not! Does this make sense? I realize that this sketch of epistolary
          infrastructure, is somewhere between banal and baffling but I
          don&rsquo;t know exactly where it is on the elephant. For further
          discussion.
        </p>
      </div>
      <ShareLinks />
      <div style={CENTER_PANEL}>
        <p>
          The share links contain a full-screen video player based on{" "}
          <ExtLink to="https://videojs.com/">VideoJS</ExtLink>, which should be
          easy enough to style and customize as-needed. And then, for use on the
          PFI website, the share links can be embedded in an{" "}
          <code>&lt;iframe&gt;</code>, just as Vimeo videos are embedded. We may
          want to think through captions, titles, and poster frames while
          implementing this.{" "}
        </p>
        <p>
          As far as I understand the network pricing, bandwidth{" "}
          <ExtLink to="https://cloud.google.com/storage/pricing#network-egress">
            costs
          </ExtLink>{" "}
          $0.12/GB. It actually gets{" "}
          <ExtLink to="https://cloud.google.com/cdn/pricing">
            a little cheaper
          </ExtLink>{" "}
          once we implement the CDN, which should also make things faster. I
          think this means it will cost us about $0.10 per hour of video
          streamed in high quality. So, filling a virutal movie theater with 150
          people who watch an hour-long program in its entirety would cost us
          $15&mdash;the price of a single ticket. The{" "}
          <ExtLink to="https://help.vimeo.com/hc/en-us/articles/12426275404305-Bandwidth-on-Vimeo">
            Vimeo bandwidth threshold
          </ExtLink>{" "}
          of 2TB indicates $200 of bandwidth on Google Cloud.
        </p>
        <p>
          I fear I'm wearing out my welcome at this cafe. Or else failing to
          appreciate my day in Paris... so: I&rsquo;ll wrap up this dispatch for
          now. Questions, testing, and feature requests are very welcome!
        </p>
        <p>Your correspondent,</p>
        <p>
          <i>R.M.O.</i>
        </p>
        <p>
          PS - did you know that <code>.ps</code> is the TLD for Palestine?
          It&rsquo;s my favorite TLD. A good next step here is to actually test
          this on the PFI website. There are several bits of clean-up that I
          should do before a very serious deployment (making a dedicated Google
          Cloud project and implementing the CDN, for instance), but my mind
          already leaps beyond these practicalities: is there a way that
          becoming reflexive about infrastructure decisions (and the ways that
          we feel failed by the status quo) is interesting to us? Would we want
          to offer this infrastructure to others?
        </p>
      </div>
    </div>
  );
};

const Main = () => {
  return <Outlet />;
};

const Watch = () => {
  const { tokenId } = useParams();

  const playerRef = React.useRef(null);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      videojs.log("player is waiting");
    });

    player.on("dispose", () => {
      videojs.log("player will dispose");
    });

    const fn = async () => {
      // figure out what we've got ... configure player appropriately
      try {
        let urlBase = "";
        if (window.location.host === "cdn.palestinefilminstitute.org") {
          urlBase = "https://cdn2.palestinefilminstitute.org";
        }

        const meta = await (
          await fetch(`${urlBase}/share/meta.json?token=${tokenId}`)
        ).json();
        console.log("got meta", meta);

        if (meta.poster) {
          player.poster(meta.poster);
        }

        for (const [lang, path] of Object.entries(meta.subs || {})) {
          console.log("adding subs", lang, path);
          player.addRemoteTextTrack({ srclang: lang, src: path }, false);
        }

        window.player = player;
      } catch (e) {
        console.warn("failed to fetch metadata", e);
        return;
      }
    };

    fn();
  };

  return (
    <VideoJS
      options={{
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [
          {
            src: `/share/hls.m3u8?token=${tokenId}`,
            type: "application/x-mpegURL",
          },
        ],
      }}
      onReady={handlePlayerReady}
    />
  );
};

const DB = React.createContext({
  db: null,
  whoami: null,
});

const Auth = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [authed, setAuthed] = useState(false);
  const [db, setDb] = useState();

  useEffect(() => {
    const fn = async () => {
      // 1. If a token is provided as `?token=XXX` forward it.
      try {
        const res = await (
          await fetch(`/api/db${window.location.search}`)
        ).json();

        setDb(res);
        setAuthed(true);

        // 3. Remove token from URL.
        console.log("resetting search...");
        //window.location.search = "";
        if (window.history && window.history.replaceState) {
          const newUrl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname;
          window.history.replaceState(null, "", newUrl);
        }
      } catch (e) {
        console.warn("auth failed", e);
      }

      setLoading(false);
    };

    fn();
  }, []);

  if (!authed) {
    return (
      <div style={CENTER_PANEL}>
        <p>
          {loading
            ? "Loading..."
            : "Hi! This part of the PFI CDN is restricted to authentic users."}
        </p>
      </div>
    );
  }

  return (
    <div>
      <DB.Provider value={db}>{children}</DB.Provider>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "",
        element: (
          <Auth>
            <GoogleOAuthProvider clientId={OAUTH_CLIENT_ID}>
              <App />
            </GoogleOAuthProvider>
          </Auth>
        ),
      },
      { path: "watch/:tokenId", element: <Watch /> },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
